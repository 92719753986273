<template>
  <div>
    <strong v-if="label !== ''">{{ label }}</strong>
    <div class="d-flex justify-md-center">
      <div v-for="val in availableValues" :key="`el__${val}__val___`">
        <div v-if="showAnchors" class="muted text-center mb-1">
          {{ getAnchor(val) }}
        </div>

        <v-tooltip bottom :disabled="getAnchor(val) === '' || printView">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              small
              @mouseover="displayChecked = true"
              @mouseout="displayChecked = false"
              @click="setValue(val)"
              :disabled="readOnly || printView"
              :class="{ 'small-scale': small }"
            >
              <v-icon
                class="scale-value-item text-center"
                :large="!small"
                :color="getIconColor(val, readOnly)"
                :style="{ color: `${getIconColor(val, readOnly)} !important` }"
                v-on="on"
                v-bind="attrs"
              >
                {{ getIconClass(val, value) }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ getAnchor(val) }}</span>
        </v-tooltip>
      </div>
    </div>
    <div v-if="hint" class="v-messages theme--light mt-4 text-center">
      <div class="v-messages__wrapper">
        <div class="v-messages__message">
          {{ hint }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import elementMixin from '@/mixins/form-element.js';
import { feedbackEnums } from '@/enums/feedbacks.js';

export default {
  props: {
    value: {
      type: Number,
      required: false,
      default: -1
    },

    availableValues: {
      type: Array,
      required: true
    },

    anchors: {
      type: Array,
      required: false,
      default: () => []
    },

    showAnchors: {
      type: Boolean,
      required: false,
      default: false
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },

    printView: {
      type: Boolean,
      required: false,
      default: false
    },

    label: {
      type: String,
      required: false,
      default: ''
    },

    small: {
      type: Boolean,
      required: false,
      default: false
    },

    valueState: {
      type: Number,
      required: false,
      default: -1
    }
  },

  mixins: [elementMixin],

  data() {
    return {
      checked: false,
      displayChecked: false,
      state: feedbackEnums.valueState.CREATED
    };
  },

  computed: {
    showChecked() {
      return this.checked || this.displayChecked;
    },

    hint() {
      if (this.valueState !== feedbackEnums.valueState.COPIED) {
        return '';
      }

      return this.$t('feedbacks.copiedValueInfo');
    }
  },

  watch: {
    availableValues() {
      if (this.availableValues.length > 5) {
        this.small = true;
      }
    }
  },

  mounted() {
    this.itemValue = this.value;
  },

  methods: {
    getAnchor(val) {
      if (
        this.anchors === null ||
        this.anchors === undefined ||
        this.anchors.length === 0
      ) {
        return '';
      }

      const anchor = this.anchors.find((x) => x.value === val);
      if (!anchor) {
        return '';
      }

      return this.localize(anchor?.translation);
    },

    setValue(val) {
      if (this.readOnly) {
        return;
      }

      if (this.itemValue === val) {
        this.itemValue = -1;
      } else {
        this.itemValue = val;
      }

      this.$emit('input', this.itemValue);
      this.$emit('change');
    }
  },

  components: {}
};
</script>

<style lang="scss" scoped>
.v-btn::before {
  background: none;
}

.v-btn.small-scale {
  padding: 0 2px;
  min-width: 30px;
}
</style>
