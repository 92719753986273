<template>
  <v-form v-model="formValid" ref="targetDialogForm">
    <v-dialog
      v-model="isVisible"
      transition="dialog-bottom-transition"
      max-width="600"
      persistent
    >
      <template v-slot:default>
        <v-card>
          <v-toolbar color="secondary">
            {{ label }}
          </v-toolbar>
          <v-card-text class="pt-4">
            <v-textarea
              :label="label"
              v-model="formItem.target"
              outlined
              filled
              required
              row="3"
              :rules="[(v) => !!v || $t('validation.fieldRequired')]"
            ></v-textarea>

            <datepicker
              :label="$t('targets.date')"
              v-model="formItem.date"
              outlined
              filled
              :first-day-of-week="1"
              disablePastDates
            ></datepicker>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="justify-end">
            <v-btn text outlined elevation="2" @click="closeDialog">
              <v-icon class="mr-2">mdi-close</v-icon>
              {{ $t('close') }}
            </v-btn>
            <v-btn text outlined elevation="2" @click.stop="saveTarget">
              <v-icon class="mr-2">mdi-floppy</v-icon>
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-form>
</template>

<script>
import datepicker from '@/components/misc/datepicker.vue';
import { v4 as uuid } from 'uuid';
import { targetEnums } from '@/enums/targets.js';

export default {
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {}
    },

    visible: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      formValid: true,
      isVisible: false,
      isEdit: false,
      formItem: {
        id: uuid()
      }
    };
  },

  computed: {
    label() {
      return this.isEdit
        ? this.$t('targets.editTarget')
        : this.$t('targets.addTarget');
    }
  },

  watch: {
    visible() {
      this.resetDialog();
      this.isVisible = this.visible;
    }
  },

  methods: {
    async saveTarget() {
      await this.$refs.targetDialogForm.validate();

      if (this.formValid) {
        if (!this.isEdit) {
          this.formItem.comments = [];
          this.formItem.subtargets = this.formItem.subtargets ?? [];
          this.formItem.status = targetEnums.targetStatus.OPEN;
        }
        this.$emit('save', this.formItem);
        await this.closeDialog();
      }
    },

    async closeDialog() {
      this.$emit('close');
      this.isVisible = false;
      await this.resetDialog();
    },

    async resetDialog() {
      if (this.value) {
        this.formItem = JSON.parse(JSON.stringify(this.value));
      } else {
        this.formItem = {
          id: uuid()
        };
      }
      await this.$refs.targetDialogForm.resetValidation();
    }
  },

  async mounted() {
    await this.resetDialog();
    this.isEdit = this.value != null;
  },

  components: {
    datepicker
  }
};
</script>

<style lang="scss" scoped>
.v-divider {
  margin-bottom: 0;

  &::before {
    border: none;
  }
}
</style>
