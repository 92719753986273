import { formEnums } from '@/enums/forms.js';

export default {
  data: () => ({
    elementType: formEnums.formElements,
    validationMode: formEnums.validationMode,
    validationResult: formEnums.validationResult,
    elementsWithoutValidation: [
      formEnums.formElements.TITLE,
      formEnums.formElements.TEXT,
      formEnums.formElements.INFO_TEXT
    ]
  }),

  methods: {
    validate() {},

    validateQuestionElement(element) {
      let elementValidationResult = this.validationResult.VALID;
      let commentValidationResult = this.validationResult.VALID;

      if (element.elementValidationMode !== this.validationMode.OPTIONAL) {
        if (element.value < 0) {
          elementValidationResult = this.validationResult.INVALID;
        }
      }

      if (element.commentValidationMode !== this.validationMode.OPTIONAL) {
        if (!element.comment || element.comment.trim() === '') {
          commentValidationResult = this.validationResult.INVALID;
        }
      }

      return {
        id: element.id,
        elementValidationMode: element.elementValidationMode,
        elementValidationResult: elementValidationResult,
        commentValidationMode: element.commentValidationMode,
        commentValidationResult: commentValidationResult,
        elementTitle: this.localize(element.title),
        elementIndex: element.index
      };
    },

    validateMatrixElement(element) {
      let elementValidationResult = this.validationResult.VALID;
      let commentValidationResult = this.validationResult.VALID;

      for (const item in element.items) {
        if (element.elementValidationMode !== this.validationMode.OPTIONAL) {
          if (item.value < 0) {
            elementValidationResult = this.validationResult.INVALID;
          }
        }

        if (element.commentValidationMode !== this.validationMode.OPTIONAL) {
          if (!item.comment || item.comment.trim() === '') {
            commentValidationResult = this.validationResult.INVALID;
          }
        }
      }

      return {
        id: element.id,
        elementValidationMode: element.elementValidationMode,
        elementValidationResult: elementValidationResult,
        commentValidationMode: element.commentValidationMode,
        commentValidationResult: commentValidationResult,
        elementTitle: this.localize(element.title),
        elementIndex: element.index
      };
    }
  }
};
