<template>
  <v-card flat class="segmented">
    <v-card-title>
      <v-row>
        <v-col cols="12">
          <h5>{{ $t('targets.targetsFromEvaluations') }}</h5>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-alert text type="info">
        {{ $t('targets.targetsFromEvaluationsDescription') }}
      </v-alert>

      <div>
        <div class="muted overline ml-2">
          {{ $t('targets.targets') }} {{ $t('targets.copiedFrom') }}
          {{ $t(`feedbacks.typeOptions.1`) }}
        </div>

        <targets-table
          v-model="selfEvaluationItems"
          :readOnly="true"
          :type="targetTableTypes.FEEDBACK_OLD"
          :comparisonOnly="comparisonOnly"
          @copyTarget="copyTarget"
        />
      </div>

      <div class="mt-8">
        <div class="muted overline ml-2">
          {{ $t('targets.targets') }} {{ $t('targets.copiedFrom') }}
          {{ $t(`feedbacks.typeOptions.2`) }}
        </div>

        <targets-table
          v-model="donorEvaluationItems"
          :readOnly="true"
          :type="targetTableTypes.FEEDBACK_OLD"
          :comparisonOnly="comparisonOnly"
          @copyTarget="copyTarget"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import targetsTable from '@/components/feedbacks/targets/targets-table.vue';
import { feedbackEnums } from '@/enums/feedbacks.js';
import { targetEnums } from '@/enums/targets.js';

export default {
  props: {
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },

    comparison: {
      type: Boolean,
      required: false,
      default: false
    },

    targets: {
      type: Array,
      required: false,
      default: () => []
    },

    conclusionTargets: {
      type: Array,
      required: false,
      default: () => []
    },

    value: {
      type: Array,
      required: false,
      default: () => []
    },

    comparisonOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      feedbackTypes: feedbackEnums.feedbackTypes,
      targetTableTypes: targetEnums.tableTypes,
      items: []
    };
  },

  computed: {
    copyIds() {
      return this.conclusionTargets
        .filter((x) => x.copyOf)
        .map((x) => x.copyOf);
    },

    selfEvaluationItems() {
      return this.items.filter(
        (x) =>
          x.feedbackType === this.feedbackTypes.SELF_EVALUATION &&
          this.copyIds.indexOf(x.id) < 0
      );
    },

    donorEvaluationItems() {
      return this.items.filter(
        (x) =>
          x.feedbackType === this.feedbackTypes.MANAGER_EVALUATION &&
          this.copyIds.indexOf(x.id) < 0
      );
    }
  },

  mounted() {
    if (this.targets && this.targets.length > 0) {
      this.items = this.targets;
    }
  },

  methods: {
    copyTarget(item) {
      this.$emit('copyTarget', item);
    },

    editTarget(item) {
      this.$emit('editTarget', item);
    }
  },

  components: {
    targetsTable
  }
};
</script>

<style lang="scss" scoped>
.targets {
  font-size: 16px;

  .subtargets {
    background-color: #4894bf;
    margin-left: -16px;
    margin-right: -16px;
    padding: 16px 16px 32px 16px;

    .subtargets--header {
      color: #9ec6dc;
      padding-left: 16px;
    }
  }

  hr {
    border-color: transparent;
  }

  hr:before {
    border-color: #9ec6dc;
  }
}

.targets-row {
  margin-top: 0;

  .targets-row--body {
    & > .row:first-of-type {
      margin-top: 0px;
    }
  }

  .targets-row--headline {
    background-color: var(--v-secondary-base);
    color: var(--v-secondary-base-font);
    padding: 16px 8px;

    i.v-icon {
      color: var(--v-secondary-base-font);
    }

    .v-btn.v-size--small {
      min-width: auto;
      width: auto;
      padding-left: 4px;
      padding-right: 4px;
    }
  }
}

.v-subheader {
  height: auto;
}

::v-deep .overline {
  font-size: 1em !important;
  font-weight: bold;
}
</style>
