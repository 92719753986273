<template>
  <v-text-field
    v-model="commentText"
    :readonly="readOnly || printView"
    hide-details="auto"
    filled
    :label="printView ? $t('notAvailable') : $t('comment')"
    :solo="printView"
    :flat="printView"
    :hint="hint"
    :persistent-hint="hint !== ''"
    @blur="leaveField"
    @focus="enterField"
  ></v-text-field>
</template>

<script>
import { feedbackEnums } from '@/enums/feedbacks.js';

export default {
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },

    printView: {
      type: Boolean,
      required: false,
      default: false
    },

    valueState: {
      type: Number,
      required: false,
      default: -1
    }
  },

  data: () => ({
    commentText: '',
    autoSaveInterval: 30000,
    autoSaveTimer: null
  }),

  computed: {
    hint() {
      if (this.valueState !== feedbackEnums.valueState.COPIED) {
        return '';
      }

      return this.$t('feedbacks.copiedValueInfo');
    }
  },

  watch: {
    commentText() {
      this.$emit('input', this.commentText);
    },

    value() {
      this.commentText = this.value;
    }
  },

  mounted() {
    this.commentText = this.value;
  },

  beforeDestroy() {
    if (this.autoSaveTimer) {
      window.clearTimeout(this.autoSaveTimer);
    }
  },

  methods: {
    enterField() {
      if (!this.readOnly && !this.printView) {
        const vm = this;
        vm.autoSaveTimer = window.setInterval(() => {
          vm.$emit('change');
        }, vm.autoSaveInterval);
      }
    },

    leaveField() {
      if (!this.readOnly && !this.printView) {
        this.$emit('change');
      }

      if (this.autoSaveTimer) {
        window.clearTimeout(this.autoSaveTimer);
      }
    }
  }
};
</script>
