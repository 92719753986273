export const formEnums = Object.freeze({
  formElements: {
    TITLE: 1,
    TEXT: 2,
    QUESTION: 3,
    MATRIX: 4,
    GROUP: 5,
    TABLE: 6,
    TARGETS: 7,
    TABLE_REVIEW: 8,
    INFO_TEXT: 9,
    ACTIONS: 10,
    CUSTOM: 100
  },

  textFieldType: {
    MULTI_LINE: 1,
    SINGLE_LINE: 2,
    DATE: 3
  },

  validationMode: {
    OPTIONAL: 0,
    MANDATORY: 1,
    OPTIONAL_WITH_WARNING: 2
  },

  validationResult: {
    VALID: 0,
    INVALID: 1
  }
});
